import React, { ComponentProps } from "react";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import classNames from "classnames";

const Switch = ({
  checked,
  checkedBgColor = "bg-default",
  disabled,
  onChange,
  screenReaderText,
}: {
  checked: boolean;
  checkedBgColor?: string;
  disabled: boolean;
  onChange?: ComponentProps<typeof HeadlessSwitch>["onChange"];
  screenReaderText: string;
}) => {
  return (
    <HeadlessSwitch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className={classNames(
        checked ? checkedBgColor : "bg-grey-44",
        "relative inline-flex flex-shrink-0 h-6 w-10 border-2 border-transparent rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        { "cursor-default": disabled },
      )}
    >
      <span className="sr-only">{screenReaderText}</span>
      <span
        aria-hidden="true"
        className={classNames(
          checked ? "translate-x-4" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition",
        )}
      />
    </HeadlessSwitch>
  );
};

export default Switch;
