import { GetMenuItemsAvailableFilters } from "api/requests/getMenuItems";
import { MenuItem } from "custom-types/MenuItem";
import { MenuItemCard } from "custom-types/MenuItemCard";
import { MenuItemV2 } from "custom-types/MenuItemV2";
import { Strain } from "custom-types/Strain";

export enum MenuItemFilterSection {
  ProductCategory = "product_category",
  DispensaryName = "dispensary_name",
  BrandName = "brand_name",
  StrainName = "strain_name",
  THCLevel = "thc",
  CBDLevel = "cbd",
  HasCBD = "has_cbd_submitted",
  HelpsWith = "helps_with",
  Weight = "normalized_quantity",
  StrainCategory = "strain_category",
  Terpenes = "strain_top_terp",
  Flavor = "top_flavors_included",
  EffectsIncluded = "top_strain_effects_included",
  PriceRange = "filterable_price",
  Deals = "deal_active",
  BrandVerified = "is_brand_verified",
  EffectsExcluded = "top_strain_negatives_excluded",
  StaffPick = "is_staff_pick",
}

export enum MenuPage {
  strains = "strains",
  dispensary = "dispensary",
  delivery = "delivery",
  brand = "brand",
  subcategory = "subcategory",
  storeLocator = "storeLocator",
}

export enum MenuFilterGroupTypes {
  tileGroup = "tile_group",
  checkBoxGroup = "checkbox_group",
  toggle = "toggle",
}

export type MenuFilterGroupValues = {
  badge: null | string;
  chip: string;
  count: number;
  label: string;
  sortOrder: number;
  type: string;
  value: string;
};
export type MenuFiltersGroup = {
  label: string;
  name: MenuItemFilterSection;
  showCount: boolean;
  sortOrder: number;
  type: MenuFilterGroupTypes;
  values: MenuFilterGroupValues[];
};

export type MenuFiltersToggle = {
  count: number;
  filterIcon: string;
  label: string;
  labelIcon: string;
  name: MenuItemFilterSection;
  sortOrder: number;
  sublabel: string;
  showCount: boolean;
  type: MenuFilterGroupTypes;
};

export type MenuFilterMap = Record<
  MenuItemFilterSection | string,
  MenuFiltersGroup | MenuFiltersToggle
>;

export type Response = {
  availableFilters: GetMenuItemsAvailableFilters;
  availableSorts: string[];
  menuItems: MenuItem[] | MenuItemV2[] | Strain[] | MenuItemCard[];
  supportedFilters: string[];
  totalItems: number;
};

export type MenuFilterOptions = {
  badge: null | string;
  count: number;
  label: string;
  sortOrder: number;
  type: string;
  showCount: boolean;
  value: string;
  docCount: number;
  key: string;
  displayName: string;
};
