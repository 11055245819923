import classNames from "classnames";
import Link from "next/link";
import { UrlObject } from "url";

import ChevronIcon from "components/Icons/ChevronIcon";

const FilterShortcut: React.FC<{
  showChevron?: boolean;
  href?: string | UrlObject;
  text: string;
  onClick?: () => void;
  className?: string;
}> = ({ showChevron = false, text, onClick, className, href }) => {
  return href ? (
    <Link
      href={href}
      aria-label={`${text} filter`}
      className={classNames(
        "border border-light-grey flex font-bold items-center leading-none rounded text-charcoal text-xs min-w-max",
        {
          "p-2": !showChevron,
          "pl-md pr-sm py-xs": showChevron,
        },
        className,
      )}
      data-testid="filter-shortcut-link"
    >
      {text}
    </Link>
  ) : (
    <button
      aria-label={`${text} filter`}
      onClick={onClick}
      className={classNames(
        "border border-light-grey flex font-bold items-center leading-none rounded text-charcoal text-xs min-w-max",
        {
          "p-2": !showChevron,
          "pl-md pr-sm py-xs": showChevron,
        },
        className,
      )}
      data-testid="mobile-filter-shortcut"
    >
      {text}
      {showChevron && (
        <ChevronIcon
          direction="right"
          className="flex-none"
          height="20"
          width="20"
          aria-hidden="true"
        />
      )}
    </button>
  );
};

export default FilterShortcut;
